<template>
  <div sign-up :class="[landingMode, channelingName && 'warp', {'fit': regionList.length <= 1}]" v-if="userInfo && ready">
    <LandingHeader v-if="!stripLayout && (landingMode || channelingName)" :landing-mode="landingMode" :lang-list="langList" :regionList="regionList" :currentRegion="currentRegion" />
    <div class="contents-list">
      <router-view v-if="siteInfo"/>
      <TrackingDownload v-if="isTracking" />
    </div>
    <LandingFooter v-if="!stripLayout && landingMode === 'warp'" ref="footer" :content="footerContent" />
  </div>
</template>

<script>

import { defaultSiteMap, npOnlySiteMap, getFixedSiteInfo } from '@/constants/base/signup/signUpSiteMap';
import { updateSignEnv, getDownloadUrl, ggpassNullQueries, initRemoveCookie } from '@/utils/baseUtil';
import { setSegment, setOneTrust, setNewRelic, setGTM } from '@/plugins/tracking';
import { state } from '@shared/utils/storeUtils';
import { appendQuery } from '@shared/utils/urlUtils';
import { regionMap, channelingSiteMap, getRegion, channelingMap } from '@/constants/base/signup/channelingSiteMap';
import LandingHeader from '@/views/components/pages/sign-up/LandingHeader.vue';
import LandingFooter from '@/views/components/pages/sign-up/LandingFooter.vue';
import TrackingDownload from '@/views/components/extra/TrackingDownload.vue';
import { brandIds, gpBrandIds, siteIds } from '@/constants/base/siteMap';
import { regionsKeys } from '@/constants/base/signup/channelingSiteMap';

export default {
  name: 'SignUp',
  components: { LandingFooter, LandingHeader, TrackingDownload },
  data() {
    return {
      ready: false,
      langList: null,
      downloadUrl: null,
      completeRedirect: null,
      footerContent: null,
      regionList: [],
      currentRegion: null,
      defaultSite: null,
      queries: null,
      regionKey: null,
    };
  },
  watch: {
    $route() {
      if (this.$route.name === 'SignUpCountry') this.defaultSite = null;
    },
    site() {
      this.updateSiteInfo(this.site);
      this.setChannelInfo(this.queries.regionKey);
    },
  },
  computed: {
    isMobile: state('browser', 'mobile'),
    env: state('env', 'env'),
    site: state('env', 'site'),
    ggpass: state('env', 'ggpass'),
    npOnly: state('env', 'npOnly'),
    brand: state('env', 'brand'),
    theme: state('env', 'theme'),
    brandSignup: state('env', 'brandSignup'),
    siteInfo: state('env', 'siteInfo'),
    isTracking: state('env', 'isTracking'),
    baseLang: state('env', 'baseLang'),
    stripLayout: state('query', 'stripLayout'),
    closeUrl: state('query', 'closeUrl'),
    landingMode: state('query', 'landingMode'),
    channelingName: state('query', 'channelingName'),
    selectedLang: state('query', 'selectedLang'),
    userInfo: state('user', 'userInfo'),
    countryCode: state('user', 'countryCode'),
    isNullSite() {
      return [siteIds.TWOACE, siteIds.GGPOK, siteIds.DAVAOPOKER, siteIds.SEVENXL].includes(this.site);
    },
    isNatural8GpBrand() {
      return this.gpBrand === gpBrandIds.NATURAL8;
    },
    isLocalOrTest() {
      return ['local', 'test'].includes(this.env);
    },
    defaultSiteInfo() {
      const siteId = this.env?.includes('local') ? (this.site || process.env.VUE_APP_SITE) : this.queries.siteId;
      const domain = window.location.host;
      return this.npOnly ? npOnlySiteMap({ domain, site: siteId }) : defaultSiteMap({ domain, site: siteId, useBlock: siteId?.length && siteId.toLowerCase() !== 'none' });
    },
  },
  methods: {
    async setUserInfo() {
      this.$store.commit('user/setUserInfo', await this.$services.base.getUserCountry());
    },
    setBonusTag(isTargetUrl) {
      /* cookies */
      const bonusTags = ['qtag', 'qtag1', 'btag', 'btag1'];
      bonusTags.forEach(i => {
        if (isTargetUrl) this.$services.cookie.setBonusTag(i, this.queries[i]);
        this.$store.commit('query/setBonusCode', this.queries);
      });
    },
    setChannelInfo(regionKey) {
      if (this.landingMode !== 'warp' && !this.channelingName) return;
      regionKey = regionKey || getRegion({ site: this.site, lang: this.queries.lang });
      if (!regionKey) {
        this.routeName('NotFound');
        return false;
      }

      this.regionList = [];
      this.regionKey = regionKey;
      const channelingInfo = channelingSiteMap(regionKey);
      channelingInfo?.supportRegions.map(i => {
        this.regionList.push({
          key: i,
          label: this.channelingName ? `${channelingMap[this.channelingName]} - ${regionMap[i].label}` : regionMap[i].label,
          value: appendQuery(regionMap[i].signUpUrl, { ...this.queries, lang: regionMap[i].lang, siteId: regionMap[i].siteIds, regionKey: i })
        });
      });
      this.footerContent = channelingInfo?.footerContent;
      this.currentRegion = regionKey ? this.regionList.find(i => i.key === regionKey) : this.regionList[0];
      this.queries = { ...this.queries, regionKey };
    },
    updateSiteInfo(site) {
      if (this.env === 'production' && [siteIds.GGPDK, siteIds.GGPCZ].includes(site)) site = undefined;
      /** GGPDK GGPCZ Stage 까지만 배포 */

      const siteInfo = site ? getFixedSiteInfo({ site, npOnly: this.npOnly }) : this.defaultSiteInfo;
      if (!siteInfo) {
        this.routeName('NotFound');
        return false;
      } else {
        this.$store.commit('env/setSiteInfo', siteInfo);
        return this.updateDefaultSite(site, siteInfo);
      }
    },
    updateDefaultSite(site, siteInfo) {
      this.defaultSite = site;
      this.langList = siteInfo.langList;

      updateSignEnv({ host: location.host, store: this.$store, route: this.$route, siteInfo, queries: this.queries, defaultSite: this.defaultSite });
      return true;
    },
    /**
     * 다이렉트로 signup의 특정 스텝으로 접근 시도 시 country 선택화면으로 이동
     * @returns {Promise<void>}
     */
    async checkPageStatus() {
      // TODO : [ean] 개발망에서는 이화면 저화면 붙을 수 있도록 처리할 때 해당 부분 수정
      if (!this.npOnly && !['SignUpCountry', 'NotSupportRegion', 'NotFound'].includes(this.$route.name) && !(this.queries.testViewMode === 'Completed' && this.isLocalOrTest)) {
        if (this.isTracking) this.queries.tracking = 'true';
        await this.replaceRouteName('SignUpCountry', null, this.queries);
      }
    },
    async setFailed(queryItems, isLocal) {
      try {
        await this.replaceRouteName('NotSupportRegion', null, { channelingUrl: isLocal ? queryItems['channelingUrl'] : location.href, checkedUrl: true });
      } catch {
        console.log('navigate duplicate');
      }
    },
    async localeInitialize() {
      const request = [brandIds.GGPUKE, brandIds.EVPUKE].includes(this.brand) ? this.brand.toUpperCase() : this.site;
      if(this.ggpass) return;
      const r = await this.$services.base.getDownloadUrl(request);
      this.$store.commit('env/setDownloadUrl', getDownloadUrl({
        brand: r.ClientBrandName || this.brand, site: this.site, siteInfo: this.siteInfo, isMobile: this.isMobile,
        baseLang: this.baseLang, queries: this.queries, baseUrl: r.ClientDownloadBaseUrl, channelingName: this.channelingName
      }));
    },
    async initialize() {
      if(!this.site) {
        await this.routeName('NotFound');
        return;
      }
      if (this.queries.channelingName === 'ocean') {
        this.$services.cookie.setCookie('QTAG', this.queries.channelingName);
      }
      if (!this.ggpass) {
        await this.checkPageStatus();
        await this.setUserInfo();
        this.setChannelInfo(this.queries.regionKey);
        this.langList = this.siteInfo.langList;
        document.body.className = this.theme;
      }

      await this.localeInitialize();
      this.setBonusTag(this.queries.isTargetUrl);

      setNewRelic();
      setSegment();
      setOneTrust(this.queries);

      initRemoveCookie();
      this.ready = true;
    },
    /**
     * web brand site 와의 연동을 위한 message listener
     * @param e
     */
    async signedInfoMessageListener(e) {
      if (e?.data && e.data.key && e.data.key === 'SET_WEB_SIGNUP_INFO') {
        const webPersonalInfo = e.data.data;
        if (webPersonalInfo && webPersonalInfo.email && webPersonalInfo.password) {
          this.$store.commit('user/setSignedEmail', webPersonalInfo.email);
          this.$store.commit('user/setSignedPw', webPersonalInfo.password);
          this.$unblock();
        }
      }
    },
    async getChannelingSupportRegions(channelingName) {
      if(!channelingName) return;

      const r = await this.$services.sign.getChannelingBonusCodes(channelingName.toLowerCase());
      let excludedList = null;
      if(r?.excluded) excludedList = r?.excluded.map(v => v.trim());
      this.$store.commit('query/setChannelingRegionList', excludedList);
    },
    setChannelingQtag(channelingName) {
      if(!channelingName) return;
      if(['ocean'].includes(channelingName)) this.$services.cookie.setCookie('QTAG', this.queries.channelingName);
    }
  },
  async mounted() {
    this.queries = ggpassNullQueries(this.$route.query);
    const { channelingName } = this.queries;

    this.setChannelingQtag(channelingName);
    await this.getChannelingSupportRegions(channelingName);

    this.updateSiteInfo(this.site);
    await this.initialize();

    // brand web signup 진입 시
    if (this.brandSignup) {
      this.$block();
      console.log('%cadd brandSignupInfo', 'color:blue');
      window.addEventListener('message', this.signedInfoMessageListener);
    }
  },
  beforeDestroy() {
    (/** @type {ModelController} */this.$model).remove(this.site);
  },
};
</script>

<style lang="less">
@import "@/less/proj.less";

.tracking {
  [sign-up] {
    @media (@tl-up) {
      .w(100%); .h(var(--innerHeight)); .min-h(800);
      .contents-list {.h(100%); .flex(); .space-between(); .items-center(); .mt(-100); .min-h(500);
        > * { .w(calc(50% - 40px));
          &:nth-of-type(1) { .rel();
            &:after { .cnt(); .wh(1, 100%); .bgc(@c-w03); .rt(-40, 40); .abs(); }
          }
        }
      }
    }
  }
}

[sign-up] { .c(white); .pb(80);
  &.landing { .pt(64); .pb(0);}
  &.warp { .pt(84); .pb(0);
    &.fit { .pt(64); }
  }
}
</style>
